// combine and shuffle two arrays

const shuffle = () => {
    const assets = [
        { image: '/assets/1.PNG'},
        { image: '/assets/2.PNG' },
        { image: '/assets/3.PNG' },
        { image: '/assets/4.PNG' },
        { image: '/assets/5.PNG' },
        { image: '/assets/6.PNG' },
        { image: '/assets/7.PNG' },
        { image: '/assets/8.PNG' },
    ];
    return [...assets, ...assets]
        .sort(()=> Math.random() - 0.5)
        .map((card)=> ({ ...card, id:Math.random()}));
};

export default shuffle;